import NavBar from "./NavBar"
import "../CSS/ContactUs.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBusinessTime, faUserTie } from "@fortawesome/free-solid-svg-icons";
import phoneIcon from "../Assets/Images/Tech/Icons/phone-icon.svg"
import timeIcon from "../Assets/Images/Tech/Icons/time-icon.svg"
import mailIcon from "../Assets/Images/Tech/Icons/mail-icon.svg"
import locationIcon from "../Assets/Images/Tech/Icons/map-icon.svg"
import ContactLady from "../Assets/Images/Tech/Contact/contact-lady.svg"
import Contact1 from "../Assets/Images/Tech/Contact/1.png"
import Contact2 from "../Assets/Images/Tech/Contact/6.png"
import Contact3 from "../Assets/Images/Tech/Contact/7.png"
import Footer from "./Footer";
import banner from "../Assets/Images/Banner/about us banner.mp4"
import banner1 from "../Assets/Images/Banner/4.jpg"
import React, { useState, useEffect } from "react";



const ContactUs = () => {
    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div>
            <NavBar navIndex="Contactus" />
            <div className="col-md-12" style={{ position: "relative", height: "250px" }}>
                <img src={banner1} className="banner-video" style={{ position: "absolute", height: "250px" }}/>
                {/* Overlay text container */}

                
                
            </div>

            <section class="section-why-choose-us light-red-bg section-contact">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            {/* <h6>Contact Us</h6> */}
                            <h2>Feel free to <span class="theme-red-text">Contact us</span> </h2>
                            {/* <p>Et purus duis sollicitudin dignissim habitant. Egestas nulla quis venenatis cras sed eu massa Et purus duis
                            </p> */}
                        </div>
                    </div>
                    <div class="row contact-details mt-5">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#home"><span><FontAwesomeIcon icon={faBook} /></span>
                                        Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#menu1"><span><FontAwesomeIcon icon={faBusinessTime} /></span>
                                        Business</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#menu2"><span><FontAwesomeIcon icon={faUserTie} /></span>
                                        Jobs</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div id="home" class="container tab-pane active">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h4 class="fw-bold theme-red-text">Courses</h4>
                                                    <p style={{ color: "black" }}>For inquiries about our courses and to discover how we can help you achieve your goals, please contact us.
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex">
                                                        <img src={phoneIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Requesting A Call:</label>
                                                            <p style={{ color: "black" }}>+91 8148628386</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex  mt-3">
                                                        <img src={mailIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>E-mail:</label>
                                                            <p style={{ color: "black" }}>admin@anglofone.co.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex ">
                                                        <img src={timeIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Monday to friday</label>
                                                            <p style={{ color: "black" }}>9 am - 8 pm</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex  mt-3">
                                                        <img src={locationIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Location:</label>
                                                            <p style={{ color: "black" }}>Race Course, Coimbatore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 contact-image-wrapper">
                                            <img src={Contact1} alt="Contact 1" className="img-fluid-contact custom-contact-image" />
                                        </div>

                                    </div>
                                </div>
                                <div id="menu1" class="container tab-pane fade">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h4 class="fw-bold theme-red-text">Business</h4>
                                                    <p style={{ color: "black" }}>For inquiries about our corporate training programs and how we can help your organization thrive, please contact us.
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex ">
                                                        <img src={phoneIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Requesting A Call:</label>
                                                            <p style={{ color: "black" }}>+91 8921907007</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex  mt-3">
                                                        <img src={mailIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>E-mail:</label>
                                                            <p style={{ color: "black" }}>manager@anglofone.co.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex ">
                                                        <img src={timeIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Monday to friday</label>
                                                            <p style={{ color: "black" }}>9 am - 8 pm</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex  mt-3">
                                                        <img src={locationIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Location:</label>
                                                            <p style={{ color: "black" }}>Race Course,Coimbatore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 contact-image-container">
                                            <img src={Contact2} alt="Contact" className="img-fluid contact-image" />
                                        </div>

                                    </div>
                                </div>
                                <div id="menu2" class="container tab-pane fade">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h4 class="fw-bold theme-red-text">Job </h4>
                                                    <p style={{ color: "black" }}>Are you interested in joining our team? Reach out to us to learn about current job openings!
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex ">
                                                        <img src={phoneIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Requesting A Call:</label>
                                                            <p style={{ color: "black" }}>+91 9363285910</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex  mt-3">
                                                        <img src={mailIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>E-mail:</label>
                                                            <p style={{ color: "black" }}>career@anglofone.co.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex ">
                                                        <img src={timeIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Monday to friday</label>
                                                            <p style={{ color: "black" }}>9 am - 8 pm</p>
                                                        </div>                      </div>
                                                    <div class="contact-info d-flex mt-3">
                                                        <img src={locationIcon} />
                                                        <div class="ps-3">
                                                            <label style={{ color: "black" }}>Location:</label>
                                                            <p style={{ color: "black" }}>Race Course, Coimbatore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 contact-image-wrapper">
                                            <img src={Contact3} alt="Contact 1" className="img-fluid-contact custom-contact-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showArrow && (
                <button
                    className="back-to-top"
                    onClick={scrollToTop}
                    aria-label="Back to top"
                >
                    ↑
                </button>
            )}
            <section
                className="map"
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    gap: "0px",
                }}
            >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7833.023217976324!2d76.973302!3d11.000189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85926b79ad9d7%3A0xfa8833d0aa627ecf!2sAnglofone%20Online%20English%20Tamil!5e0!3m2!1sen!2sin!4v1728054993821!5m2!1sen!2sin"
                    width="49.5%"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.427257101951!2d78.38732569999999!3d17.4392528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb913561bbdd2d%3A0xd9b8df9b59730480!2sAnglofone%20Online%20English%20Telugu!5e0!3m2!1sen!2sin!4v1734948840076!5m2!1sen!2sin"
                    width="49.5%"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

                {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.7636358585758!2d76.96238568532151!3d11.022348261497113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859bccb592abd%3A0x5942509404dc1484!2sCodomo%20Edtech!5e0!3m2!1sen!2sin!4v1734602871643!5m2!1sen!2sin"
                    width="33%"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}
            </section>

            <footer>
                <Footer />
            </footer>

        </div>
    )
}

export default ContactUs;