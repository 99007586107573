import React from "react";
import NavBar from ".././NavBar";
import Footer from ".././Footer";
import "../../CSS/HomePage.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePageCourse from ".././HomePageCourse";
import bagIcon from "../../Assets/Images/Tech/AboutUs/why-choose-icon.svg"
import whyChooseUs from "../../Assets/Images/Tech/AboutUs/why-choose-img.svg"
import faqImg from "../../Assets/Images/Tech/AboutUs/faq-img.svg"
import partnerlogo from "../../Assets/Images/Tech/Clients/partner-logo.svg"
import quote from "../../Assets/Images/Tech/Clients/icon-quote.svg"
import sat from "../../Assets/Images/Tech/Clients/sat-1.svg"
import { Carousel } from 'react-bootstrap';
import us from "../../Assets/Images/Tech/AboutUs/Why-choose-anglofone-2.gif"
import { useState, useEffect } from "react";
import faqImg1 from "../../Assets/Images/Tech/AboutUs/FAQ new.mp4"
import clients from "../../Assets/Images/AboutUs/what people stay.mp4"
import us1 from "../../Assets/Images/Tech/AboutUs/Why choose anglofone 2.mp4"
import why1 from "../../Assets/Images/Tech/AboutUs/1.png"
import why2 from "../../Assets/Images/Tech/AboutUs/2.png"
import why3 from "../../Assets/Images/Tech/AboutUs/3.png"
import why4 from "../../Assets/Images/Tech/AboutUs/4.png"
import course4 from "../../Assets/Images/Tech/Course/4.jpg"
import course1 from "../../Assets/Images/Tech/Course/beginner course.png"
import course7 from "../../Assets/Images/Tech/Course/7.jpg"
import course8 from "../../Assets/Images/Tech/Course/foundation course.png"
import arrow from "../../Assets/Images/Tech/Course/readmore-arrow-icon.svg"
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import whatsappIcon from "../../Assets/Images/Tech/Icons/whatsap-white-icon.svg";
import { FaInstagram, FaYoutube, FaFacebook } from "react-icons/fa";
import sat1 from "../../Assets/Images/Tech/Clients/60K studentrs.svg";
import sat2 from "../../Assets/Images/Tech/Clients/digital folowers.svg";
import sat3 from "../../Assets/Images/Tech/Clients/google review.svg";
import sat4 from "../../Assets/Images/Tech/Clients/trainer.svg";

const LanguageTelugu = () => {

    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        const slides = ['c1', 'c2', 'c3', 'c4'];
        let currentIndex = 0;

        const interval = setInterval(() => {
            // Uncheck the current slide
            document.getElementById(slides[currentIndex]).checked = false;

            // Move to the next slide
            currentIndex = (currentIndex + 1) % slides.length;

            // Check the next slide
            document.getElementById(slides[currentIndex]).checked = true;
        }, 5000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);



    const [hovered, setHovered] = useState(null);

    const gridItems = [
        { img: bagIcon, title: "Complete personal attention", desc: "Complete Personalized Attention.", position: "top-left" },
        { img: bagIcon, title: "Practical learning with extended support", desc: "Learn with real-life examples in your mother tongue.", position: "top-right" },
        { img: bagIcon, title: "Trusted by clients", desc: "Learn anytime from anywhere.", position: "bottom-left" },
        { img: bagIcon, title: "British Council Certified Trainers", desc: "Practice with British council certified trainers.", position: "bottom-right" },
    ];
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
    return (
        <div>
            <NavBar navIndex="Region" />

            {/* Social Media Icons */}
            <div className="banner-container">
                <section className="grow-banner">
                    <div id="carouselExampleIndicators" className="carousel slide pointer-event" data-bs-ride="carousel">
                        <div className="carousel-inner ">
                            <div className="carousel-item about-banner_tel active">
                                <div className="container">
                                    <div className="banner align-items-center">
                                        <div className="banner-in ">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {/* <h1>Regional / Language</h1> */}
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item">
                                                                {/* <a href="index.html">Home</a> */}
                                                            </li>
                                                            <li className="breadcrumb-item active" aria-current="page">
                                                                {/* <a href="index.html">Regional</a> */}
                                                            </li>
                                                            <li className="breadcrumb-item" aria-current="page">
                                                                {/* <a href="index.html">Language</a> */}
                                                            </li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <div className="social-icons">
                    <a href="https://www.instagram.com/anglofoneonlineenglish_telugu/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="social-icon" />
                    </a>
                    <a href="https://www.youtube.com/@anglofoneonlineenglish_telugu" target="_blank" rel="noopener noreferrer">
                        <FaYoutube className="social-icon" />
                    </a>
                    <a href="https://www.facebook.com/AnglofoneOnlineEnglishTelugu?rdid=nouNB5YQAiC8aPLw&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FmKjVzYpApc5cExFH%2F" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="social-icon" />
                    </a>
                </div>

            </div>

            <section className="section-testimonials" style={{ backgroundColor: "#fef1f0" }}>
                <div style={{ padding: "50px", textAlign: "center" }}>
                    <h1><span style={{ fontSize: "40px", fontWeight: "bold", color: "#2D194E" }}>Welcome to</span> <span style={{ fontSize: "40px", fontWeight: "bold", color: "#B41B05" }}>Anglofone</span></h1>
                    <br></br><p className=" col-md-8 text-center mx-auto theme-red-text" style={{ fontWeight: "bold" }}> ఎక్కడ నుంచి  అయినా English నేర్చుకునే అత్యుత్తమమైన platform. </p>
                </div>
                {/* Courses */}
                <div className="mx-auto">
                    <section className="section-course">
                        <div className="container">
                            <div className="course-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 style={{ fontWeight: "bold", fontSize: "35px" }}>మీ English ను ANGLOFONE తో Improve చేసుకోండి.
                                            <br></br>
                                            {/* <br></br> */}
                                            {/* <span className="theme-red-text"  style={{fontWeight: "bold"}}>OUR COURSES </span> */}
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                                        <div className="course-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src={course8} className="img-fluid" alt="course-img" />
                                                </div>
                                                <div className="overlay">
                                                    <div className="course-details">
                                                        <h4>Foundation Course</h4>
                                                        <p style={{ fontWeight: "bold" }}>
                                                            FOUNDATION COURSE లో మీరు  BASIC GRAMMAR అలానే ప్రతిరోజు మన నిజ జీవితంలో  USE చేసే words ను నేర్చుకుంటారు.</p>
                                                        <a href="https://wa.link/v3qp5o" className="banner_link_l">
                                                            Join Now<img src={whatsappIcon} alt="WhatsApp Icon" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                                        <div className="course-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src={course1} className="img-fluid" alt="course-img" />
                                                </div>
                                                <div className="overlay">
                                                    <div className="course-details">
                                                        <h4>Beginner Course</h4>
                                                        <p style={{ fontWeight: "bold" }}>BEGINNER COURSE లో మీరు PROPER గా sentencesను frame చేయడం మరియు English లో ఒకరితో  communicate చేయడం నేర్చుకుంటారు.</p>
                                                        <a href="https://wa.link/ebwnc1" className="banner_link_l">
                                                            Join Now<img src={whatsappIcon} alt="WhatsApp Icon" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                                        <div className="course-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src={course4} className="img-fluid" alt="course-img" />
                                                </div>
                                                <div className="overlay">
                                                    <div className="course-details">
                                                        <h4>Mediate Course</h4>
                                                        <p style={{ fontWeight: "bold" }}>MEDIATE COURSE లో మీరు మీ grammar మరియు vocabulary ని మెరుగుపరచుకొని ధైర్యంగా English లో మాట్లాడగలరు.</p>
                                                        <a href="https://wa.link/vr7p7g" className="banner_link_l">
                                                            Join Now<img src={whatsappIcon} alt="WhatsApp Icon" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                                        <div className="course-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src={course7} className="img-fluid" alt="course-img" />
                                                </div>
                                                <div className="overlay">
                                                    <div className="course-details">
                                                        <h4>Speak-Out Course</h4>
                                                        <p style={{ fontWeight: "bold" }}>SPEAK OUT COURSE లో  మీ లెవెల్ ఆధారంగా ప్రత్యేకంగా syllabus ను రూపొందించి English లో స్పష్టంగా మాట్లాడేలా practice చేయిస్తాం.</p>
                                                        <a href="https://wa.link/7zv85j" className="banner_link_l">
                                                            Join Now<img src={whatsappIcon} alt="WhatsApp Icon" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {/* Why choosing us */}
                <div className="mx-auto">
                    <div className="mx-auto-heading">
                        Why Choose <span className="theme-red-text">Anglofone ?</span>
                    </div>
                </div>

                <section
                    className="section-why-choose-us d-flex justify-content-center align-items-center"

                >
                    <div className="container">
                        <div className="row" >
                            {/* First Section - Items */}

                            <div className="col-md-6">
                                <div class="wrapper_inside_l">
                                    <div class="container_inside_l">
                                        <input type="radio" name="slide" id="c1" defaultChecked />
                                        <label htmlFor="c1" className="card_l">
                                            <div className="row">
                                                <div className="icon_l"><img src={why1} /></div>
                                                <div className="decs_align_l">
                                                <div className="desc_l">
                                                    <h4>ఎప్పుడైనా,ఎక్కడనుంచైనా  ENGLISH నేర్చుకోవచ్చు.</h4>
                                                    {/* <p>Winter has so much to offer - creative activities</p> */}
                                                </div>
                                                </div>
                                            </div>
                                        </label>

                                        <input type="radio" name="slide" id="c2" />
                                        <label htmlFor="c2" className="card_l">
                                            <div className="row">
                                                <div className="icon_l"><img src={why2} /></div>
                                                <div className="decs_align_l">
                                                    <div className="desc_l">
                                                    <h4>British council certified trainers తో  practice చేయవచ్చు.</h4>
                                                    {/* <p>Gets better every day - stay tuned</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </label>

                                        <input type="radio" name="slide" id="c3" />
                                        <label htmlFor="c3" className="card_l">
                                            <div className="row">
                                                <div className="icon_l"><img src={why3} /></div>
                                                <div className="decs_align_l">
                                                <div className="desc_l">
                                                    <h4>పూర్తి వ్యక్తిగత శ్రద్ధతో నేర్చుకోవచ్చు.</h4>
                                                    {/* <p>Help people all over the world</p> */}
                                                </div>
                                                </div>
                                            </div>
                                        </label>

                                        <input type="radio" name="slide" id="c4" />
                                        <label htmlFor="c4" className="card_l">
                                            <div className="row">
                                                <div className="icon_l"><img src={why4} /></div>
                                                <div className="decs_align_l">
                                                <div className="desc_l" >
                                                    <h4 >మన మాతృభాషలో నిజ జీవిత ఉదాహరణలతో  ENGLISH EASY గా  నేర్చుకోవచ్చు.</h4>
                                                    {/* <p>Space engineering becomes more and more advanced</p> */}
                                                </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* Second Section - Simple Hello Div */}
                            <div className="col-md-6 d-flex align-items-center justify-content-center" style={{ backgroundColor: "inherit", overflow: "hidden", height: "550px" }}>
                                <div>
                                    <video src={us1} className="videoGif" autoPlay loop muted playsInline>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* faq */}
                <section className="section-faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <video src={faqImg1} className="img-fluid d-block mx-auto" autoPlay loop muted playsInline  >
                                </video>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="spacing"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        At what time will my English class commence?
                                                    </button>
                                                </h4>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#ccordionExample">
                                                    <div className="accordion-body">

                                                        At Anglofone, we have flexibly structured our online English courses. You can learn spoken English
                                                        in your leisure time through WhatsApp. Your dedicated trainers will guide you by adapting to your
                                                        schedules.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Will I able to speak fluently within 2 months?
                                                    </button>
                                                </h4>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Learning pace is something that differs from person to person. Our course duration is 2 months. In
                                                        addition to that, your course is valid up to 9 months from which you can become more fluent and
                                                        learn beyond the course even after your course duration.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What is the procedure to enroll in the course?
                                                    </button>
                                                </h4>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can text the mentioned contact number on our website. Our course advisor will reach you. There
                                                        will be an online test to evaluate your level in English. After evaluating your test, our advisors
                                                        will suggest the suitable course to you, considering
                                                        your level of English.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        I'm working abroad. How can I enroll in the course?
                                                    </button>
                                                </h4>
                                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can access our English classes from any part of the world because our trainers are working
                                                        round the clock. We can definitely coordinate with your timings.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Will I have a dedicated trainer to guide me?
                                                    </button>
                                                </h4>
                                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Yes. You will have 2 personal trainers, 1 calling activity trainer, and 1 live trainer throughout
                                                        the course. Your personal trainers will guide you on grammar-related doubts. Your calling activity
                                                        trainer will guide with your spoken English in telephonic
                                                        conversations. Your live trainer will mentor you with soft skills and teach you to converse
                                                        comfortably in groups.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        What if I missed my video class sessions on a day?
                                                    </button>
                                                </h4>
                                                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can inform your reasons to your trainers, and they will be able to guide you regarding class
                                                        compensation.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* achievements */}
                {/* <section className="section-achievements light-red-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h6>OUR ACHIEVEMENTS</h6>
                                <h2>Some of our Achievements
                                    <br />
                                    <span className="theme-red-text">AWARDS</span></h2>
                            </div>


                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-2 col-md-3">
                                        <div className="partner-box">
                                            <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-2 col-md-3">
                                        <div className="partner-box">
                                            <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-2 col-md-3">
                                        <div className="partner-box">
                                            <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-2 col-md-3">
                                        <div className="partner-box">
                                            <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-2 col-md-3">
                                        <div className="partner-box">
                                            <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-2 col-md-3">
                                        <div className="partner-box">
                                            <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section> */}
                {/* section Testimonials */}
                <br></br>
                <br></br>
                <section className="ection-testimonials">
                    <div className="container">
                        <div className="testimonial-box">
                            <div className="row">
                                <div className="col-md-4">
                                    <video src={clients} className="img-fluid d-block mx-auto" autoPlay loop muted playsInline style={{ position: "relative", borderRadius: "30px", overflow: "hidden", }} >
                                    </video>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="ps-6">
                                                {/* <h6>Testimonials</h6> */}
                                                <h2>
                                                    Success
                                                    {/* <span className="theme-red-text"> Stories</span> */}
                                                </h2>
                                                <img src={quote} className="img-fluid d-block" style={{ height: '20px' }} />
                                                <Carousel
                                                    controls={false}   // Hide next/prev arrows
                                                    indicators={true}  // Show pagination dots
                                                    interval={3000}    // Auto-slide every 3 seconds
                                                    pause={false}      // Don't pause on hover
                                                >
                                                    {/* Slide 1 */}
                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Prathisha</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}>
                                                                The class is very engaging and interactive.
                                                                It provides a good balance between language inprovement, pronounciation, fluency.
                                                                The frequent speaking practice through calling activity and video sessions. It helps me in building my language fluency.
                                                                I am able to speak with confidence now all because of this.
                                                            </p>

                                                        </div>

                                                    </Carousel.Item>

                                                    {/* Slide 2 */}
                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Ranjith</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}> I am fully satisfied learning English with you .
                                                                Because, I expected what I need that's all are teaching us. For instance, Grammar, Accent, Vocabularies and Spoken activities.
                                                                Thus, I can improve my knowledge and spoken skill. On top of that all trainers all are doing well great job.
                                                                I appreciate your job and Thank you so much to all. </p>

                                                        </div>
                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Pavithra</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}>
                                                                Anglofone is a great platform for learning English. The trainers are excellent, and the training methods are very effective. I learn new things day by day, and I can see a difference in my learning. The classes are really good, and the trainers are encouraging and motivating. I can understand grammar very well, and it is so interesting.
                                                            </p>

                                                        </div>

                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Sandhiya Ganesh</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}>
                                                                Anglofone's online classes are very helpful. The trainers are very supportive and always ready to help. After sending in my tasks, they check quickly and give the feedback. They explain any mistakes right away, making it easy to understand. I’m thankful to the trainers for their help. The calling activities are also great for improving.
                                                            </p>

                                                        </div>

                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Ayyanar G</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}>

                                                                I had a fantastic experience with Anglo Fone Spoken English classes! The teachers are not only knowledgeable but also very supportive. My confidence in speaking English has greatly improved thanks to the live sessions and engaging teaching methods. Whether you're a beginner or looking to improve your fluency, this is the perfect place to enhance your English skills. I highly recommend Anglo Fone to anyone looking to excel in spoken English.</p>

                                                        </div>

                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Nandhini N</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}>

                                                                When I joined the Anglofone online Beginner class, I didn't have confidence in speaking English. But now I have develop in my communication skills, improved my vocabulary and include learning how to frame sentences. we had weekly calling activities, which helped me learn a lot. So, thank you to my trainers for their support. Thank you Anglofone for a great learning experience.</p>

                                                        </div>

                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Chandri Joe</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}>

                                                                Anglofone has been instrumental in helping me learn English effectively. The engaging lessons and supportive teachers made the process enjoyable and smooth. I highly recommend it to anyone looking to improve their language skills.</p>

                                                        </div>

                                                    </Carousel.Item>



                                                    <Carousel.Item>
                                                        <div className="align-items-start" >
                                                            <div className="author">
                                                                <h5>Rohini. P</h5>

                                                            </div>
                                                            <p style={{ color: "#727272" }}> I am very satisfied with this course. The trainer is extremely caring and approachable. I feel confident that by the end of this 3-month course, I'll be able to understand and speak English fluently💯. I’m grateful for this opportunity, and I want to thank the trainer for their dedication and support. 😊♥
                                                            </p>

                                                        </div>

                                                    </Carousel.Item>


                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="satisfied-box">
                        <div className="row">
                            <div className="col-md-5">
                                {/* <h6>Success Story</h6> */}
                                <h2 style={{ textAlign: "center", fontWeight: 800 }}>Why we are the best ?</h2>

                                <br></br>
                                <ul>
                                    <h4 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Friendly and reliable support</h4>
                                    <h4 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Customized learning plans</h4>
                                    <h4 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Certified experts</h4>
                                </ul>


                            </div>
                            <div className="col-md-7 d-flex align-items-center" ref={ref}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                            <div className="image-wrapper">
                                                <img src={sat4} width={30} className="img-fluid" alt="Trainers" />
                                            </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={75} duration={2} />}+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Trainers</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                            <div className="image-wrapper">
                                                <img src={sat3} width={30} className="img-fluid" alt="Reviews" />
                                            </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={6.5} decimals={1} duration={2} />}K+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Google reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                            <div className="image-wrapper">
                                                <img src={sat1} width={30} className="img-fluid" alt="Students" />
                                            </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={60} duration={2} />}K+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Students</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                            <div className="image-wrapper">
                                                <img src={sat2} width={30} className="img-fluid" alt="Followers" />
                                                </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={2.5} decimals={1} duration={2} />}M+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Digital followers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
            </section>
            {showArrow && (
                <button
                    className="back-to-top"
                    onClick={scrollToTop}
                    aria-label="Back to top"
                >
                    ↑
                </button>
            )}
            <footer>
                <Footer />
            </footer>
        </div>
    )
}

export default LanguageTelugu;